import React, { Component } from "react";
import {Box} from 'grommet'

class PostsTableItem extends Component {
  render() {
    // const route = this.props.location.pathname;
    return <Box pad={'small'} margin={'medium'}>This page is Under Construction </Box>;
  }
}

export default PostsTableItem;
