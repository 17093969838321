const ERROR = 'ERROR';
const LOADING = 'LOADING';
const SEARCH = 'SEARCH';
const POSTS = 'POSTS';
const POST_DELETE = 'POST_DELETE';
const POST_UPLOAD = 'POST_UPLOAD';
const USER = 'USER';
const USERS = 'USERS';
const SET_USER = 'SET_USER';
const USER_DELETE = 'USER_DELETE';
const USER_SELECT = 'USER_SELECT';
const USER_UPDATE = 'USER_UPDATE';
const ALL_USERS = 'ALL_USERS';
const AUTHENTICATE = 'AUTHENTICATE';
const PURGE = 'PURGE';

export {
  ERROR,
  LOADING,
  SEARCH,
  POSTS,
  SET_USER,
  USER,
  USERS,
  POST_DELETE,
  USER_SELECT,
  USER_DELETE,
  USER_UPDATE,
  ALL_USERS,
  AUTHENTICATE,
  PURGE,
  POST_UPLOAD
};
