import React from 'react'

/**
* @author
* @function PostMetadata
**/

const PostMetadata = () => {
    return (
        <h1> Post Metadata </h1>
    )
}

export default PostMetadata